.modal-body{
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    color: rgba(0,0,0,.85);
}
ul{
    padding-left: 40px;
}
ul li{
    list-style: disc;
}
table{
    display: block;
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
}
