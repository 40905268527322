.commonProblemContainer {
  height: auto;
  padding: 120px 5%;
  background-color: #2E3442;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .common-line {
    width: 100%;
  }

  .commonProblemLogo {
    /* width: 305px; */
    height: auto;
    margin: 40px 0 40px 0;
    font-size: 40px;
    font-weight: bold;
    width: fit-content;
  }

  /* .commonProblemLogo span {
      color: #C5AC79;
      font-size: 52px;
  } */

  .arrowImg {
    width: 20px;
    height: auto;
    margin-right: 25px;
  }

  .content {
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    
  }

  .ant-collapse {
    border: 1px solid #30323A;
    overflow: hidden;
    border-radius: 8px;
    background: #1F2129;
  }
  .ant-collapse > .ant-collapse-item{
    border-bottom: 1px solid #30323A;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background-color: #1F2129;
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
    border: none;
    padding: 40px;
    width: 100%;
  }

  .commonProblemContainer  .el-collapse-item__wrap {
    border: none;
  }
  .ant-collapse-content{
    border: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    background-color: #282C34;
    color: #ffffff;
    font-weight: 600;
    border: none;
    text-align: left;
    padding: 40px;
    font-size: 16px;
  }

  .custom-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-title div {
    text-align: left;
  }

  .el-collapse-item:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #30323A;
  }
}

@media screen and (max-width: 900px) {
  .commonProblemContainer {
    padding: 40px 24px;
    height: auto;
    .commonProblemLogo {
      /* width: 190px; */
      height: auto;
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 24px;
    }
    .commonProblemContainer  .el-collapse {
      border: 1px solid #30323A;
      overflow: hidden;
      border-radius: 8px;
      background: #1F2129;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.96px;
      width: 100%;
      padding: 16px;
    }
    .ant-collapse-content > .ant-collapse-content-box{
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 2px;
      padding: 24px 16px;
    }

    .commonProblemContainer  .el-collapse-item__content {
      background-color: #282C34;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 2px;
      padding: 24px 16px;
    }

    .commonProblemContainer  .el-collapse-item__arrow {
      display: none;
    }


    .custom-title {
      align-items: normal;
    }

    .arrowImg {
      width: 16px;
      height: 9px;
      margin-right: 16px;
      margin-top: 12px;
    }
  }
}
