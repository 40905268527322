@import '~antd/dist/antd.css';
body {
  margin: 0;
  background-color:  #181A20;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bottombar {
  padding: 32px 5%;
  background-color: #181A20;
  color: #ffffff;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.showNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.navContainer a {
  color: #fff;
  text-decoration: none;
  margin-right: 30px;
  font-weight: 600;
  font-size: 16px;

}


.navContainer a:hover {
  color: #c5ac79;
  transition: 0.5s;
}

.other span:hover {
  color: #c5ac79;
  transition: 0.5s;
}

.diver {
  height: 1px;
  background-color: #30323A;
  margin: 20px 0 30px 0;
}

.payforType img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.four-logo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.actionContainer {
  display: flex;
}

.displayItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.payforTypeTitle {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 20px;
}

.copyright {
  color: #666666;
  font-size: 14px;
}

.other span {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.showMobileLogo {
  display: none;
}

.diverMobile {
  display: none;
}

.four-logo {
  display: none;
}

@media screen and (max-width: 900px) {
  .four-logo {
    display: flex;
    justify-content: center;
  }

  .four-logo a img {
    height: 35px;
    margin: 17px 17px 17px 0;
  }

  .bottombar {
    height: auto;
    padding: 40px 24px;
    width: auto;
  }

  .showNav {
    display: none;
  }

  .showMobileLogo {
    display: block;
    margin-bottom: 40px;
  }

  .diver {
    display: none;
  }

  .displayItem {
    flex-direction: column;
  }

  .payforTypeTitle {
    color: #CCC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .payforType {
    margin-bottom: 40px;
  }

  .copyright {
    margin-bottom: 24px;
    margin-top: 40px;
  }

  .other {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .diverMobile {
    display: block;
    height: 1px;
    background-color: #2E3442;
    width: 100%;
  }
}
