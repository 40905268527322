.navbar {
  height: 104px;
  background-color: #181A20;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 999;
  overflow-x: auto;
  padding-left: 20px;
  padding-right: 20px;

  box-sizing: border-box;
}
.ant-modal{
  top: 0;
  max-width: 100vw;
}
.menuDialog{
  .ant-modal-content{
    background-color: transparent;
  }
  .ant-modal-body{
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.menu-item:hover {
  color: #c5ac79;
  transition: 0.5s;
}

.audit:hover {
  background: linear-gradient(0deg, #2B2E39, #2B2E39),
  linear-gradient(0deg, #E3C076, #E3C076);
}

.topLogo {
  width: 163px;
  height: auto;
}


.el-dropdown {
  width: 130px !important;
  height: 44px;
  background-color: #23252A !important;
  border-radius: 22px;
  // justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.ant-dropdown-menu {
  width: 150px !important;
  background-color: #1A1B21;
  height: 320px;
  overflow: auto;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
  color: #ffffff !important;
}
.ant-dropdown-menu-item-active{
  color: #1A1B21!important;
}
.menuLink {
  width: 100%;
  display: flex;
  background-color: transparent;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}


.langMenu {
  margin-left: 15px;
}



.sticky {
  position: fixed;
  top: 0;
  z-index: 10;
}

.navContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px 20px;
}


.navContainer a {
  color: #fff;
  text-decoration: none;
  padding: 0 20px;
  font-weight: 600;
  font-size: clamp(14px, 16px, 18px);
  cursor: pointer;
  white-space: nowrap;
  margin-right: 0;
}

.actionContainer {
  display: flex;
}

.pledge {
  /* width: 104px; */
  min-width: 104px;
  /* padding: 0px 5px; */
  height: 44px;
  border-radius: 10px;
  background-color: #C5AC79;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #181A20;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.pledge:hover {
  background-color: #FFD581;
}

.audit {
  min-width: 76px;
  padding: 5px 10px;
  white-space:nowrap;
  height: 44px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 24px;
  border: 1px solid #C5AC79;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.menuImg {
  display: none;
}

.zhMenuImg {
  width: 30px;
  height: 30px;
  margin-left: -8px;
}

.zhImg {
  width: 30px;
  height: 30px;
  /* margin-right: 10px; */
  margin-left: 8px;
}

.mobileNavContainer {
  height: calc(100vh - 100px);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.mobileNavContainer a {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.actionContainer .el-popper__arrow {
  display: none;
}

.actionContainer  .el-popper {
  border: none;
}

.actionContainer  .el-dropdown-menu__item {
  color: white;
}

.actionContainer  .el-dropdown-menu__item:hover {
  color: #FFF;
  background-color: #1A1B21;
}

.mobileNavContainer .el-popper__arrow {
  display: none;
}

.mobileNavContainer  .el-popper {
  border: none;
}

.mobileNavContainer  .el-dropdown-menu__item {
  color: white;
}

.mobileNavContainer  .el-dropdown-menu__item:focus {
  color: #FFF;
  background-color: #1A1B21;
}
.mobileMenu{
  overflow: auto;
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: 100vw;
    margin: 0;
  }
}
@media screen and (max-width: 1000px) {
  .navbar {
    padding: 0 5%;
    height: 80px;
    background-color: #181A20;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menuLink {
   
    margin-right: 45px;
  }

  .topLogo {
    width: 90px;
    height: auto;
  }

  .navContainer {
    display: none;
  }

  .actionContainer {
    display: none;
  }

  .menuImg {
    width: 24px;
    height: 24px;
    display: block;
    position: fixed;
    right: 20px;
  }
}
