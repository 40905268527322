p,
h1,
ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
}

body {
    height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-variant: tabular-nums;
    color: white;
    font-feature-settings: "tnum";
    line-height: 1.5;
}

label {
    font-weight: 700;
}

html {
    height: 100%;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

*,
*:before,
*:after {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /*font-family: Work Sans;*/
    box-sizing: border-box;
}

a:focus,
a:active {
    outline: none;
}

a,
a:focus,
a:hover {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
}

div:focus {
    outline: none;
}


@font-face {
    font-family: Work Sans;
    src: url("../font/WorkSans.f8112219.ttf");
}

@font-face {
    font-family: belarius-serif-wide;
    src: url("../font/Belarius-Serif-Wide-Regular.ttf");
}
.home{
    overflow-x: hidden;
    padding-top: 104px;
}

@media screen and (min-width:960px) {
    .moblie-1 {
        display: none !important;
    }
}

@media screen and (max-width:960px) {
    .pc-1 {
        display: none !important;
    }
    .home{
        padding-top: 80px;
    }
}

@media (min-width: 2000px) {

    .preSale,
    .cryptoNews,
    .operationContainer,
    .pledgeCoin,
    .economicToken,
    .safeContainer,
    .CoinChanceContainer,
    .coinTimesContainer,
    .whiteBookContainer,
    .weGameContainer,
    .whyPopularContainer,
    .noKyc,
    .licenseBox,
    .commonProblemContainer,
    .howtoBuyTop,
    .howtobuyContainer .step,
    .container {
        padding-left: 18% !important;
        padding-right: 18% !important;
    }

    .home-wrapper {
        padding-left: 5% !important;
        padding-right: 8% !important;
        box-sizing: border-box;
    }

    .whiteBookImg {
        padding-right: 9%;
    }

    .navContainer {
        justify-content: flex-end !important;
    }

    .navContainer a {
        margin-right: 30px !important;
    }
}
.ant-btn{
    color: #181a20 !important;
}
