.whyPopularContainer {
  padding: 120px 5%;
  background-color: #1F2129;
  color: #ffffff;
  position: relative;
  .loopitem{
    display: flex !important;
    align-items: center;
    justify-content: space-around;
  }
  &:hover{
    .btnLeft, .btnRight{
      display: block;
    }
  }
  .btnLeft{
    position: absolute;
    top: 50%;
    left: 6%;
    color: white;
    width: 14px;
    z-index: 10;
    cursor: pointer;
    opacity: 0.5;
    display: none;
  }
  .btnRight{
    position: absolute;
    top: 50%;
    right: 6%;
    color: white;
    width: 14px;
    z-index: 10;
    cursor: pointer;
    opacity: 0.5;
    display: none;
  }
}

.whyPopularContainer  .el-carousel__container {
  display: flex;
  height: 550px;
}

.whyPopularContainer  .el-carousel--horizontal {
  width: 100%;
}

.whyPopularContainer  .el-carousel__indicators--horizontal {
  display: none;
}

.el-carousel__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.whyPopularDesc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.desc {
  max-width: 700px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left;
  color: #FFFFFF99;
  font-size: 20px;
  letter-spacing: 0.8px;
}

.play {
  padding: 14px 52px;
  border-radius: 8px;
  background-color: #C5AC79;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #181A20;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #EFD8AA;
}

.whyPopularLogo {
  color: #C5AC79;

  /* height: 63px; */
  font-size: 40px;
  font-weight: bold;
}
.whyPopularLogo span{
  font-size: 52px;
}

.whyPopularMobileLogo {
  display: none;
}



.whyPopularDetailContainer {
  width: 540px;
  height: auto;
}

.whyPopularDetail {
  width: 540px;
  height: auto;
}

.whyPopularDetailMobileContainer {
  display: none;
}

.progress-bar {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: absolute;
  width: 540px;
  right: 0;
  bottom: 20px;
}

.progress-segment {
  width: 156px;
  height: 2px;
  background-color: #505050;
  position: relative;
  z-index: 1;
}

.progress-segment-top {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 2px;
  background-color: #C5AC79;
  transition: width 1s ease-in-out;
}

.progress-segment-action {
  width: 156px;
}

.progress-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #505050;
  position: absolute;
}

.progress-dot.active {
  background-color: #C5AC79;
}

.progress-wrapper {
  display: flex;
  align-items: center;
}
.ant-carousel{
  .slick-dots{
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .whyPopularContainer {
    padding: 40px 24px;
    height: auto;
    flex-direction: column;
  }

  .whyPopularDetailContainer {
    display: none;
  }

  .whyPopularLogo {
    display: none;

  }

  .whyPopularDetailMobileContainer {
    display: block;
  }

  .whyPopularDetailMobile {
    display: block;
    width: 327px;
    height: 172px;
    margin-top: 24px;
  }

  .whyPopularMobileLogo {
    display: block;
    width: 100%;
    height: auto;
    font-size: 24px;
    font-weight: bold;
    color: #C5AC79;
  }
  .whyPopularMobileLogo:nth-child(2){
    font-size: 20px;
  }

  .whyPopularMobileLogo span{
  
    font-size: 32px;
  }
  .whyPopularMobileLogo:nth-child(2) span{
    font-size: 22px;
  }

  .desc {
    width: 100%;
    margin-top: 36px;
    margin-bottom: 24px;
    color: rgba(255, 255, 255, 0.60);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.56px;
  }

  .play {
    color: #181A20;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.64px;
  }


  .progress-bar {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: absolute;
    width: 300px;
    right: calc((100% - 300px) / 2);
    bottom: 16px;
  }

  .progress-segment {
    width: 94px;
    height: 2px;
    background-color: #505050;
    position: relative;
    z-index: 1;
  }

  .progress-segment-top {
    position: absolute;
    z-index: 2;
    width: 0;
    height: 2px;
    background-color: #C5AC79;
    transition: width 1s ease-in-out;
  }

  .progress-segment-action {
    width: 94px;
  }

  .progress-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #505050;
    position: absolute;
  }

  .progress-dot.active {
    background-color: #C5AC79;
  }

}
