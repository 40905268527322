.licenseBox {
    padding: 70px 5%;
    background-color: #1F2129;
    .domainContainer{
      // margin-top: 40px;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.6);
      h1{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 12px;
        color: #fff;
      }
      h2{
        font-size: 20px;
        font-weight: 700;
        margin-top: 40px;
        margin-bottom: 15px;
        color: #fff;
      }
      .domain-img{
        max-width: 110px;
        margin: 40px 0 15px;
        img{
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .text-primary{
        color: #EFD8AA;
      }
      .text-text-green{
        color: #22C55E;
      }
    }
  
  }
  