.my-pledge {
  position: relative;
  .ant-input{
    width: calc(100% - 20px);
  }
  .ant-input, .ant-input[disabled]{
    background-color: #30323A;
    border: 1px solid #434755;
    border-radius: 9px;
    height: 54px;
    margin-bottom: 20px;
    color: #FFF;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}




.my-pledge .bg2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #C5AC79;
  text-align: center;
  line-height: 55px;
  font-weight: bold;
}

.my-pledge .bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-pledge .bg .btn {
  background-color: #C5AC79;
  color: rgb(24, 26, 32);
  text-align: center;
  height: 55px;
  line-height: 55px;
  width: 180px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

.container1 {
  background: url('../../../public/img/indexBackgroud.png') center/cover;
  padding: 20px 5% 120px 5%;

}

.pledgeDetail {
  padding: 48px 40px 40px 40px;
  flex-direction: column;
  border-radius: 24px;
  border: 1px solid #30323A;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.25) inset;
  backdrop-filter: blur(5px);
  background: #181A20;
  color: #ffffff;
}

.desc {
  font-size: 20px;
  color: #FFFFFF99;
  font-weight: 400;
  margin-top: 24px;
  margin-bottom: 32px;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.learnMore {
  width: 50%;
  border: 1px solid #EFD8AA;
  height: 52px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  color: #C5AC79;
  margin-right: 20px;
  background-color: #181A20;
}

.learnMore:hover {
  background: linear-gradient(0deg, #2B2E39, #2B2E39),
  linear-gradient(0deg, #E3C076, #E3C076);
}

.actionBtn {
  width: 50%;
  height: 52px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
  color: #181A20;
  border: 1px solid #EFD8AA;
  background: #C5AC79;
}
.actionBtn:hover {
  background-color: #FFD581;
}
.data {
  display: flex;
  justify-content: space-between;
}
.dataContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #30323A;
  border-radius: 8px;
  height: 120px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border: 1px solid #434755;
  margin-bottom: 18px;
}

.goPledge {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.outPledge {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.goPledgeBtn {
  display: flex;
  flex-direction: row;
}

.actionPledge {
  display: flex;
}

.actionPledgeTitle {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 24px;
  margin-top: 32px;
}

.actionPledgeDesc {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
}

.actionPledge  .el-input__wrapper {
  padding: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  background: #30323A;
  border-radius: 9px;
}

.actionPledge  .el-input__inner {
  border: 1px solid #434755;
  background: #30323A;
  border-radius: 8px;
  width: 100%;
  height: 52px;
  padding-left: 10px;
  margin: 0;
  font-size: 20px;
}

.maxVal {
  position: absolute;
  right: 25px;
  top: 4px;
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #EFD8AA;
  background: rgba(197, 172, 121, 0.30);
  color: #C5AC79;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.maxVal:hover {
  background: linear-gradient(0deg, #2B2E39, #2B2E39),
  linear-gradient(0deg, #E3C076, #E3C076);
}

.receive {
  position: absolute;
  right: 25px;
  top: 4px;
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #EFD8AA;
  background: rgba(197, 172, 121, 0.30);
  color: #C5AC79;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.receive:hover {
  background: linear-gradient(0deg, #2B2E39, #2B2E39),
  linear-gradient(0deg, #E3C076, #E3C076);
}

.minePledge {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.minePledgeItem {
  display: flex;
  flex-direction: column;
  flex: 1;

}

.minePledge  .el-input__wrapper {
  padding: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  background: #30323A;
  border-radius: 9px;
  width: 25%;
}





.minePledge  .el-input__inner {
  border: 1px solid #434755;
  background: #30323A;
  border-radius: 8px;
  width: 100%;
  height: 52px;
  padding-left: 10px;
  margin: 0;
  font-size: 20px;
}

.dataTitle {
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dataValue {
  color: #ffffff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}


.pledgeDetailLogo {
  width: 120px;
  height: auto;
  font-size: 38px;
  font-weight: bold;
  color: #C5AC79;
}

.pledgeDetailAllBit {
  /* width: 306px; */
  height: auto;
  margin-top: 48px;
  margin-bottom: 34px;
  font-size: 38px;
  font-weight: bold;
  color: #C5AC79;
}
@media screen and (max-width: 900px) {
  .my-pledge{
    .ant-input{
      width: 100%;
    }
    .ant-input, .ant-input[disabled]{
      background-color: #30323A;
      border: 1px solid #434755;
      border-radius: 9px;
      height: 46px;
      margin-bottom: 20px;
    }

  }

  .actionPledge {
    flex-direction: column;
  }

  .pledgeDetailLogo {
    width: 90px;
    font-size: 28px;
  }

  .pledgeDetail {
    padding: 40px 24px;
  }

  .desc {
    color: rgba(255, 255, 255, 0.60);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.56px;
    margin-top: 22px;
    margin-bottom: 16px;
  }

  .data {
    flex-wrap: wrap;
  }

  .dataContainer {
    margin-right: 0px;
    margin-bottom: 16px;
    height: 80px;

  }

  .dataTitle {
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
  }

  .dataValue {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.8px;
  }

  .actionPledgeTitle {
    margin-top: 8px;
    margin-bottom: 16px;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
  }

  .actionPledgeDesc {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.56px;
    margin-bottom: 10px;
  }

  .learnMore {
    color: #C5AC79;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.64px;
    height: 44px;
  }

  .actionBtn {
    color: #181A20;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.64px;
    height: 44px;
  }

  .maxVal {
    color: #C5AC79;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.56px;
    right: 3px;
    top: 3px;
    padding: 8px 10px;
  }

  .receive {
    color: #C5AC79;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.56px;
    right: 3px;
    top: 3px;
    padding: 8px 10px;
  }

  .minePledge {
    flex-direction: column;
  }

  .minePledge  .el-input__wrapper {
    padding: 0;
    margin-right: 0px;
    height: 44px;
  }

  .actionPledge  .el-input__wrapper {
    padding: 0;
    margin-right: 0px;
    height: 44px;
  }

  .minePledge  .el-input__inner {
    height: 44px;
    font-size: 14px;
  }

  .actionPledge  .el-input__inner {
    height: 44px;
    font-size: 14px;
  }

  .pledgeDetailAllBit {
    height: auto;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 32px;
  }

  .outPledge {
    margin-top: 20px;
  }
}


