.container {
    height: 28px;
    border-radius: 12px;
}
.bit-cellprogress {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: #30323a;
}
.bit-bar {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: #c5ac79;
}
.bar-text {
    font-size: 14px;
    margin-right: 8px;
    line-height: 28px;
    text-align: end;
    color: #FFF;
}