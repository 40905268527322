.preSale {
  background-color: #2e3442;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 120px 5%;
  overflow: hidden;
 .ant-col{
   padding-bottom: 32767px !important;
   margin-bottom: -32767px !important;
  }
  .el-row.is-align-middle {
    align-items: normal;
  }
  .desc {
    color: rgba(255, 255, 255, 0.6);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.8px;
    margin-top: 32px;
    margin-bottom: 80px;
  }

  .descItem {
    height: 100%;
    background-color: #1f2129;
    border-radius: 8px;
    border: 1px solid #30323a;
    align-items: flex-start;
    padding: 32px;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 12px;
  }

  .subtitle {
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.64px;
  }

  .preSaleTitleImg {
    /* width: 472px; */
    /* height: 63px; */
    font-size: 40px;
    font-weight: bold;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
  }

  .preSaleImg {
    width: 60px;
    height: 60px;
    margin-bottom: 34px;
  }

}



@media screen and (max-width: 900px) {
  .preSale {
    padding: 40px 24px;
    .preSaleTitleImg {
      /* width: 294px; */
      height: auto;
      font-size: 24px;
    }

    .desc {
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: 24px;
    }

    .descItem {
      height: auto;
      margin-bottom: 16px;
      flex-direction: row;
      padding: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
    }

    .title {
      font-size: 16px;
      margin-bottom: 5px;
      margin-left: 16px;
    }

    .subtitle {
      font-size: 14px;
      margin-left: 16px;
    }

    .preSaleImg {
      width: 40px;
      height: 40px;
      margin-bottom: 0;
    }
  }


}
