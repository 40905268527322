.CoinChanceContainer {
  padding: 120px 5%;
  background-color: #1F2129;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .coinChanceLogo {
    /* width: 385px; */
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    /* height: 63px; */
    font-size: 40px;
    font-weight: bold;
  }

  .CoinChanceDesc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .desc {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: left;
    color: #FFFFFF99;
    font-size: 20px;
    letter-spacing: 0.8px;
  }

  .coinChanceDetailImgae {
    width: 400px;
    height: auto;
  }

}

@media screen and (max-width: 900px) {
  .CoinChanceContainer {
    padding: 40px 24px;
    height: auto;
    flex-direction: column;

    .coinChanceLogo {
      /* width: 190px; */
      height: auto;
      font-size: 26px;
    }

    .desc {
      width: 100%;
      font-size: 14px;
      margin-bottom: 24px;
      letter-spacing: 0.56px;
    }

    .coinChanceDetailImgae {
      width: 100%;
      height: auto;
    }
  }
}
