.cryptoNews {
  padding: 0% 5%;
  background-color: #1F2129;
  min-height: 152px;
  .newsPng {
    width: 200px;
  }
}

@media screen and (max-width: 900px) {
  .cryptoNews {
    padding: 20px;
    .newsPng {
      width: 80%;
      margin: 8px auto;
      opacity: 0.8;
      display: block;
    }
  }
}
